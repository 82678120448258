import React, { useState, useEffect } from "react";
import { Row, Col, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import { SocialIcon } from "react-social-icons";
import avatar from "./images/my_imgs/avatar.png";
import rabbit from "./images/icons/rabbit.png";
import { headingStyle, bioStyle } from "./components/styles";
import Typewriter from "./components/Typewriter"; // Adjust the path if necessary
const Header = () => {
  const [style, setStyle] = useState({
    textAlign: "start",
    padding: "5vw",
  });

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth <= 700) {
        setStyle({
          textAlign: "center",
          padding: "10vw",
        });
      } else if (screenWidth <= 1000) {
        setStyle({
          textAlign: "center",
          padding: "8vw",
        });
      } else {
        setStyle({
          textAlign: "end",
          padding: "5vw",
        });
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Set initial styles based on screen size

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const tooltip = (
    <Tooltip id="tooltip" style={{ fontSize: "14px" }}>
      The one I used to know, she called me "Thỏ" /rabbit/.
    </Tooltip>
  );

  return (
    <Row
      style={{
        height: "100vh", // Fullscreen section
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: style.textAlign,
        padding: style.padding,
      }}
    >
      <Col
        xs={{ span: 12, order: 1 }}
        sm={{ span: 7, order: 1 }}
        md={{ span: 6, order: 1 }}
        lg={{ span: 7, order: 1 }}
      >
        <Typewriter
          style={{ marginTop: "20px" }}
          text="Hii, I'm Dustin"
          speed={100}
        />
        <p style={bioStyle}>
          I'm a Software Engineering Honours graduate from Monash University and
          currently a Research Engineer at CSIRO's Data61. I'm just making this
          website to tell the story in my way.
        </p>
        <div style={{ marginTop: "30px" }}>
          <SocialIcon
            url="https://www.linkedin.com/in/vuminhduc796/"
            style={{ marginRight: "15px" }}
          />
          <SocialIcon
            url="https://www.facebook.com/vuminhduc30/"
            style={{ marginRight: "15px" }}
          />
          <SocialIcon url="https://github.com/vuminhduc796" />
        </div>
      </Col>
      <Col
        xs={{ span: 12, order: 1 }}
        sm={{ span: 12, order: 1 }}
        md={{ span: 6, order: 2 }}
        lg={{ span: 5, order: 2 }}
        style={{ textAlign: "center" }}
      >
        <a href="https://www.instagram.com/mjnduc/">
          <Image
            src={avatar}
            style={{
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Soft, subtle shadow
              width: "80%",
              borderRadius: "20px",
              marginTop: "max(48px, 3vw)",
            }}
          />
        </a>
      </Col>
    </Row>
  );
};

export default Header;
