import React from "react";
import ScrollToTopButton from "./components/GoTopBtn";
import { Link, Element } from "react-scroll";
import Header from "./Header";
import ProjectSection from "./ProjectSection";
import WorkSection from "./WorkSection";
import Education from "./Education";
import Achievements from "./Achievements";
import useFadeInOnScroll from "./components/useFadeInOnScroll"; // Import the custom hook
import Skills from "./Skills";
import Personality from "./Personality";

const HomePage = () => {
  const [headerVisible, headerRef] = useFadeInOnScroll();
  const [workVisible, workRef] = useFadeInOnScroll();
  const [projectsVisible, projectsRef] = useFadeInOnScroll();
  const [skillsVisible, skillsRef] = useFadeInOnScroll();
  const [educationVisible, educationRef] = useFadeInOnScroll();
  const [achievementsVisible, achievementsRef] = useFadeInOnScroll();
  const [personalityVisible, personalityRef] = useFadeInOnScroll();
  return (
    <div className="container">
      <div
        ref={headerRef}
        className={`section fade-in-section ${
          headerVisible ? "is-visible" : ""
        }`}
      >
        <Element name="">
          <Header />
        </Element>
      </div>
      <div
        ref={skillsRef}
        className={`section fade-in-section ${
          skillsVisible ? "is-visible" : ""
        }`}
      >
        <Element name="skills">
          <Skills />
        </Element>
      </div>
      <div
        ref={workRef}
        className={`section fade-in-section ${workVisible ? "is-visible" : ""}`}
      >
        <Element name="work">
          <WorkSection />
        </Element>
      </div>

      <div
        ref={projectsRef}
        className={`section fade-in-section ${
          projectsVisible ? "is-visible" : ""
        }`}
      >
        <Element name="projects">
          <ProjectSection />
        </Element>
      </div>

      <div
        ref={educationRef}
        className={`section fade-in-section ${
          educationVisible ? "is-visible" : ""
        }`}
      >
        <Element name="education">
          <Education />
        </Element>
      </div>

      <div
        ref={achievementsRef}
        className={`section fade-in-section ${
          achievementsVisible ? "is-visible" : ""
        }`}
      >
        <Element name="achievements">
          <Achievements />
        </Element>
      </div>
      <div
        ref={personalityRef}
        className={`section fade-in-section ${
          personalityVisible ? "is-visible" : ""
        }`}
      >
        <Element name="personality">
          <Personality />
        </Element>
      </div>

      <ScrollToTopButton />

      {/* Example navigation links */}
      <nav>
        <Link
          activeClass="active"
          to="projects"
          spy={true}
          smooth={true}
          duration={1000}
        >
          Projects
        </Link>
        <Link
          activeClass="active"
          to="work"
          spy={true}
          smooth={true}
          duration={1000}
        >
          Work
        </Link>
        <Link
          activeClass="active"
          to="skills"
          spy={true}
          smooth={true}
          duration={1000}
        >
          Skills
        </Link>
        <Link
          activeClass="active"
          to="education"
          spy={true}
          smooth={true}
          duration={1000}
        >
          Education
        </Link>
        <Link
          activeClass="active"
          to="achievements"
          spy={true}
          smooth={true}
          duration={1000}
        >
          Achievements
        </Link>
        <Link
          activeClass="active"
          to="personality"
          spy={true}
          smooth={true}
          duration={1000}
        >
          Personality
        </Link>
      </nav>
    </div>
  );
};

export default HomePage;
