import React from "react";
import ai_img from "./images/skills/ai.png";
import android_img from "./images/skills/android.png";
import web_img from "./images/skills/web.png";
import xcode from "./images/skills/xcode.png";
import wrench from "./images/icons/wrench.png";
import {
  sectionHeadingStyle,
  sectionContainerStyle,
  headingContainerStyle,
  bioStyle,
} from "./components/styles";
import { Button } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Image } from "react-bootstrap";
import SkillCardItem from "./components/SkillCard";
const Skills = () => {
  const skills = [
    { title: "Android" },
    { title: "Kotlin" },
    { title: "Frontend" },
    { title: "Java" },
    { title: "Python" },
    { title: "AI/ML" },
    { title: "LLMs" },
    { title: "ReactJS" },
    { title: "NextJS" },
    { title: "Redux" },
    { title: "Hooks" },
    { title: "Bootstrap" },
    { title: "DaisyUI" },
    { title: "Flask" },
    { title: "AWS" },
    { title: "Heroku" },
    { title: "Jetpack Compose" },
    { title: "MVVM" },
    { title: "Deep Learning" },
    { title: "Machine Learning" },
    { title: "Agile Methodologies" },
    { title: "Automated Testing" },
    { title: "JUnit" },
    { title: "NodeJS" },
    { title: ".NET" },
    { title: "Angular" },
    { title: "GraphQL" },
    { title: "Flutter" },
    { title: "C#" },
    { title: "MVP" },
    { title: "MVC" },
    { title: "Git" },
    { title: "Gitflow" },
    { title: "CI/CD" },
    { title: "Kanban" },
    { title: "Trello" },
    { title: "Dart" },
    { title: "Firebase SDK" },
    { title: "Responsive UI Design" },
    { title: "Voice Assistance" },
    { title: "Optical Head-Mounted Display" },
    { title: "Market Research" },
    { title: "Product Validation" },
    { title: "Image-Sharing Platform" },
    { title: "Mobile Social Apps" },
  ];
  const halfSkills =
    window.innerWidth <= 768
      ? skills.slice(0, Math.ceil(skills.length / 2))
      : skills;
  return (
    <div style={sectionContainerStyle}>
      <div style={headingContainerStyle}>
        <h2 style={sectionHeadingStyle}>Skills</h2>{" "}
        <Image
          src={wrench}
          style={{
            width: "20px",
            marginLeft: "10px",
          }}
        />
      </div>
      <p style={bioStyle}>
        I quite love learning new stuffs and my skills are just the combination
        of interesting things I found on the way.
      </p>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          overflowX: "auto",
          gap: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            overflowX: "auto",
            paddingBottom: "20px",
            gap: "10px",
          }}
        >
          {halfSkills.map((object, i) => (
            <div
              key={i}
              style={{ flex: "0 0 auto", width: "auto", margin: "0px" }}
            >
              <SkillCardItem obj={object} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Skills;
