import { useEffect, useState, useRef } from "react";

const useFadeInOnScroll = () => {
  const [isVisible, setIsVisible] = useState(false);
  const domRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    });

    const { current } = domRef;
    if (current) {
      observer.observe(current);
    }

    return () => observer.disconnect();
  }, []);

  return [isVisible, domRef];
};

export default useFadeInOnScroll;
