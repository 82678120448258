import React from "react";
import trophy from "./images/icons/trophy.png";
import { Image, Row, Col } from "react-bootstrap";
import Timeline from "./components/HorizontalTimeLine";
import {
  sectionHeadingStyle,
  sectionContainerStyle,
  headingContainerStyle,
  bioStyle,
} from "./components/styles";
const Achievements = () => {
  return (
    <div style={sectionContainerStyle}>
      <div style={headingContainerStyle}>
        <h2 style={sectionHeadingStyle}>Achievements</h2>{" "}
        <Image
          src={trophy}
          style={{
            width: "20px",
            marginLeft: "10px",
          }}
        />
      </div>

      <Timeline />
    </div>
  );
};

export default Achievements;
