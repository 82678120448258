import "bootstrap/dist/css/bootstrap.min.css";
import HomePage from "./HomePage";
import { Link, Element } from "react-scroll";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function App() {
  return (
    <div className="App" style={{ background: "#F7F8FA" }}>
      <HomePage />
    </div>
  );
}

export default App;
