import { width } from "@fortawesome/free-solid-svg-icons/fa0";

const fontSizeNormal = "max(1.5vw, 16px)"; // Adjusted to ensure readability at smaller sizes
const textShadowTitle = "1px 1px 3px rgba(0, 0, 0, 0.1)"; // Softer shadow for a subtle effect

export const sectionHeadingStyle = {
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'SF Pro Display', 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
  fontWeight: "600", // Slightly less bold for a clean look
  textAlign: "start",
  fontSize: "max(2.4vw, 28px)", // Adjusted to maintain balance
  textShadow: textShadowTitle,
  color: "#333333", // Consistent dark gray for headings
  marginBottom: "20px", // Ensure spacing consistency
  width: "100%",
};
export const sectionContainerStyle = {
  minHeight: "100vh", // Ensure the section takes up at least full screen height
  padding: "5vw", // Padding to ensure spacing from screen edges
  backgroundColor: "#F7F8FA", // Consistent background color
  textAlign: "center", // Center the text for a clean look
};

export const itemHeading = {
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'SF Pro Text', 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
  textAlign: "start",
  fontSize: "20px", // Slightly smaller for better hierarchy

  color: "#444444", // Slightly lighter than section heading
};

export const itemDesSmall = {
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'SF Pro Text', 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
  fontWeight: "400", // Normal weight for readability
  textAlign: "start",
  fontSize: "14px", // Slightly smaller for descriptions
  color: "rgba(54, 49, 41, 0.7)", // Increased opacity for better readability
  lineHeight: "1", // Improved line height for readability
};

export const headingStyle = {
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'SF Pro Display', 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
  fontSize: "2.5rem", // Keep this impactful
  fontWeight: "600",
  color: "#333333", // Consistent with section heading color
  marginBottom: "1rem",
  textAlign: "center", // Centered for a balanced look
};

export const bioStyle = {
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
  color: "#555", // Softer gray for body text
  textAlign: "justify",
  maxWidth: "100%", // Restrict the width for better readability
};

export const descriptionStyle = {
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'SF Pro Text', 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
  padding: "0px",
  margin: "0px",
  textAlign: "justify",
  fontSize: fontSizeNormal,
  color: "#555555", // Consistent with bioStyle
  lineHeight: "1.6", // Ensure readability
};

export const pageStyle = {
  width: "100%",
  textAlign: "justify",
  paddingLeft: "8vw",
  paddingRight: "8vw",
  fontSize: fontSizeNormal,
  color: "#333333", // Ensure the text color is consistent
  lineHeight: "1.6", // Consistent line height across the page
};

export const headingContainerStyle = {
  marginTop: "3vh",
  display: "flex", // Use flexbox for alignment
  justifyContent: "space-between", // Space between items (left and right alignment)
  alignItems: "center", // Vertically center the items
  width: "100%", // Ensure the container takes up the full width
};

export const containerStyle = {
  padding: "20px",
  marginBottom: "20px",
  background: "#fff",
  borderRadius: "10px",
  border: "0px",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
};
