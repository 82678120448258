import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { itemHeading, itemDesSmall, bioStyle } from "./styles";

const Timeline = () => {
  const cardStyle = {
    padding: "16px", // Reduced padding for a more compact look
    paddingBottom: "0px",
    margin: "10px",
    border: "none",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Subtle shadow for the cards
    backgroundColor: "#fff",
    borderRadius: "10px",
  };

  const scrollableContainerStyle = {
    maxHeight: "80vh", // Limit the height of the container
    overflowY: "auto", // Enable vertical scrolling
    paddingRight: "15px", // Add padding for content visibility
    marginRight: "-15px", // Shift content to hide the scrollbar
    "-msOverflowStyle": "none", // Hide scrollbar in Internet Explorer and Edge
    scrollbarWidth: "none", // Hide scrollbar in Firefox
  };

  const items = [
    {
      date: "2021",
      type: "Achievement",
      title: "Monash Faculty of Engineering Deans Honour List",
      content:
        "Recognized for achieving a weighted average of above 80% during the 2021 academic year at Monash University's Faculty of Engineering.",
      place: "Monash University",
    },
    {
      date: "2018 - 2022",
      type: "Achievement",
      title: "5x Academic Excellence Awards",
      content:
        "Recipient of five Academic Excellence Awards, including the highest score in iOS app development (98 HD) and four other units during my study at Monash University.",
      place: "Monash University",
    },
    {
      date: "2022",
      type: "Scholarship",
      title: "2x Summer and Winter Vacation Research Scholarships",
      content:
        "Conducted impactful research on improving the accessibility and usability of mobile apps under the guidance of academic supervisors from Monash University.",
      place: "Monash University",
    },
    {
      date: "2022",
      type: "Activity",
      title: "Student Representative",
      content:
        "Collaborated with industrial partners to enhance the academic activities for Software Engineering at Monash University as a Student Representative.",
      place: "Monash BSE Advisory Board",
    },
    {
      date: "2022",
      type: "Certification",
      title: "Deep Learning Specialization",
      content:
        "Received certification for completing a comprehensive 4-month training program on Deep Learning offered by DeepLearning.AI.",
      place: "DeepLearning.AI",
    },
    {
      date: "2021",
      type: "Certification",
      title: "Cloud Practitioner",
      content:
        "Certified by Amazon Web Services (AWS) for expertise in AWS products and services, demonstrating proficiency in cloud computing.",
      place: "Amazon Web Services",
    },
    {
      date: "2020",
      type: "Activity",
      title: "Python Bootcamp Tutor",
      content:
        "Served as a tutor for a Python Bootcamp at Monash University, guiding students in mastering the fundamentals of the Python programming language.",
      place: "Monash University",
    },
    {
      date: "2019",
      type: "Activity",
      title: "Student Leader",
      content:
        "Took on leadership responsibilities in organizing student activities, fostering a vibrant and engaging community.",
      place: "Monash University",
    },
  ];

  return (
    <div style={scrollableContainerStyle}>
      {items.map(function (object, i) {
        return (
          <Card key={i} style={cardStyle}>
            <Row>
              <Col
                className="d-flex align-items-center"
                xxl="2"
                xs="2"
                sm="2"
                md="2"
                lg="2"
                xl="2"
                style={{ padding: "10px" }}
              >
                <p style={itemDesSmall}> {object.date}</p>
              </Col>
              <Col>
                <h3 style={itemHeading}>{object.title}</h3>
                <p style={itemDesSmall}>
                  {object.type} @ {object.place}
                </p>
                <p style={bioStyle}>{object.content}</p>
              </Col>
            </Row>
          </Card>
        );
      })}
    </div>
  );
};

export default Timeline;
