import React, { useState } from "react";
import { Row, Col, Image } from "react-bootstrap";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import work_icon from "./images/icons/work_icon.png";
import {
  sectionHeadingStyle,
  itemHeading,
  itemDesSmall,
  sectionContainerStyle,
  containerStyle,
  headingContainerStyle,
  bioStyle,
} from "./components/styles";

const jobs = [
  {
    date: "June 2023 - now",
    shortDes:
      "Securing a full-time position as a research engineer at CSIRO's Data61 was a remarkable coincidence that fueled my desire to produce impactful outcomes and contribute to this dynamic organization's remarkable work.",
    longDes: [
      "Responsible for the 'AI for Visualisation' project.",
      "Working as Front-end developer in the Fast tracking chickpea lipid characterisation project",
      "Contributing to User Task Automation project - an entrepreneur project backed by CSIRO.",
      "Commencing ON Prime to learn more about market research and validation.",
      "Participating in 2 other projects in collaboration with Monash University and National University of Singapore.",
    ],
    title: "Research Engineer @ CSIRO's Data61",
  },
  {
    date: "Feb 2022 - now",
    shortDes:
      "Introduced by a dear friend, I fulfilled my teaching aspiration in 2022 as a Monash University teaching associate, connecting with students who appreciate my passion for guiding and inspiring them.",
    longDes: [
      "Taught FIT2081 Mobile application development (S1-2022, S1-2023) and FIT2099 - Object-oriented design and implementation (S2-2022, S1-2023).",
      "Designed teaching material for in-class activities.",
      "Received 100/100 weighted Student Evaluation for teaching.",
    ],
    title: "Teaching Associate @ Monash University",
  },
  {
    date: "Nov 2021 - June 2022",
    shortDes:
      "In 2022, a fortuitous encounter with Dr. Chunyang Chen ignited my passion for research at Monash University. Joining as a research assistant, I embarked on a thrilling exploration of cutting-edge ideas, resulting in our publication Voicify Your UI: Towards Android App Control with Voice Commands in UbiComp 2023, affirming my dedication to pushing knowledge boundaries.",
    longDes: [
      "Project: Voicify Android Apps: Towards Android App Control with Voice Commands.",
      "Project: Toolkit for Usability & Accessibility Testing of Android App.",
    ],
    title: "Research Assistant @ Monash University",
  },
  {
    date: "Feb 2021 - Apr 2022",
    shortDes:
      "As a software engineer at SEEDSoft, I gained transformative technical knowledge and entrepreneurship insights from Chloe Le, the company's founder, expanding my horizons and laying the foundation for future endeavors.",
    longDes: [
      "Flutter with Firebase for mobile app development.",
      "Full-stack web development using ReactJS framework.",
      "Experience with relational and non-relational databases.",
    ],
    title: "Software Engineer @ SEEDSoft",
  },
  {
    date: "Nov 2017 - Jan 2021",
    shortDes:
      "I started my first job as a waiter, venturing into diverse roles like Bobarista and Cook, gaining invaluable experiences. Through 3-4 jobs, I persevered until landing my first IT role. Reflecting on my journey, I take pride in the personal growth achieved.",
    longDes: [
      "Bobarista @ Sharetea for 4 months.",
      "Bobarista @ Gotcha for 1 year.",
      "Cook @ Gami for 1 year.",
    ],
    title: "The start of my journey",
  },
];

const WorkSection = () => {
  const [expanded, setExpanded] = useState([false, false, false, false, false]);

  const toggleExpand = (index) => {
    setExpanded((prevState) => {
      const updatedExpanded = [...prevState];
      updatedExpanded[index] = !updatedExpanded[index];
      return updatedExpanded;
    });
  };

  return (
    <div style={sectionContainerStyle}>
      <div style={headingContainerStyle}>
        <h2 style={sectionHeadingStyle}>Work</h2>
        <Image
          src={work_icon}
          style={{
            width: "20px",
            marginLeft: "10px",
          }}
        />
      </div>

      <div style={scrollableContainerStyle}>
        {jobs.map((job, i) => (
          <Col xs={12} key={i} style={containerStyle}>
            <div onClick={() => toggleExpand(i)} style={{ cursor: "pointer" }}>
              <h3 style={itemHeading}>{job.title}</h3>
              <p style={itemDesSmall}>{job.date}</p>
              <p style={bioStyle}>{job.shortDes}</p>
              {expanded[i] && (
                <div>
                  <ul>
                    {job.longDes.map((item, index) => (
                      <li key={index} style={bioStyle}>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div style={iconContainerStyle}>
                <button style={iconButtonStyle}>
                  <FontAwesomeIcon
                    icon={expanded[i] ? faChevronUp : faChevronDown}
                  />
                </button>
              </div>
            </div>
          </Col>
        ))}
      </div>
    </div>
  );
};

const scrollableContainerStyle = {
  maxHeight: "80vh", // Limit the height of the job list to 60% of the viewport height
  overflowY: "auto", // Enable vertical scrolling
  paddingRight: "15px", // Add padding to prevent content from being hidden behind the scrollbar
  marginRight: "-15px", // Hide the scrollbar by shifting the content
  "-msOverflowStyle": "none", // Internet Explorer 10+
  scrollbarWidth: "none", // Firefox
};

const listItemStyle = {
  marginBottom: "5px",
};

const iconContainerStyle = {
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "10px",
};

const iconButtonStyle = {
  border: "none",
  background: "none",
  cursor: "pointer",
};

export default WorkSection;
