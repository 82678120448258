import React from "react";
import { Card } from "react-bootstrap";

import { bioStyle, itemHeading } from "./styles";

const SkillCardItem = ({ obj }) => {
  if (obj === undefined) {
    return <Card></Card>;
  }
  const { title, link } = obj;

  return (
    <Card
      href={link}
      style={{
        border: "none", // Remove the border for consistency
        paddingLeft: "6px",
        paddingRight: "6px",
        borderRadius: "15px", // Adjusted to match other components
        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.05)", // Consistent subtle shadow
        transition: "transform 0.3s ease, box-shadow 0.3s ease", // Smooth transition for hover effects
      }}
    >
      <div
        style={{
          paddingLeft: "6px",
          paddingRight: "6px",
          paddingBottom: "-4px",
          paddingTop: "4px",
        }}
      >
        <p style={bioStyle}>{title}</p>
      </div>
    </Card>
  );
};

export default SkillCardItem;
