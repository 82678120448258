import React, { useState } from "react";
import { Card, Image } from "react-bootstrap";
import { itemHeading, itemDesSmall, bioStyle } from "../components/styles";

const CardItem = ({ obj }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  if (!obj) {
    return <Card></Card>;
  }

  const { title, subtitle, description, image, link } = obj;

  const handleClick = () => {
    window.open(link, "_blank"); // Opens the link in a new tab
  };

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div
      style={{
        perspective: "1000px", // Add perspective to create 3D effect
        width: "100%",
        height: "250px", // Fixed height for the card
        margin: "12px",
        marginTop: "30px",
      }}
      onMouseEnter={handleFlip}
      onMouseLeave={handleFlip}
    >
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          transformStyle: "preserve-3d",
          transition: "transform 0.6s",
          transform: isFlipped ? "rotateY(180deg)" : "rotateY(0deg)",
        }}
      >
        {/* Front of the card */}
        <Card
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backfaceVisibility: "hidden", // Hide backface when flipped
            borderRadius: "15px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            overflow: "hidden",
            border: "none",
          }}
        >
          <Image
            src={image}
            style={{
              width: "100%",
              height: "150px", // Fixed height for the image
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
              objectFit: "cover",
            }}
          />
          <div style={{ padding: "15px" }}>
            <h3 style={itemHeading}>{title}</h3>
            <h4 style={itemDesSmall}>{subtitle}</h4>
          </div>
        </Card>

        {/* Back of the card */}
        <Card
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backfaceVisibility: "hidden", // Hide backface when flipped
            transform: "rotateY(180deg)",
            borderRadius: "15px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            overflow: "hidden",
            backgroundColor: "#ffffff", // Ensure a different background color for contrast
            display: "flex",
            flexDirection: "column",
            textAlign: "justify",
          }}
          onClick={handleClick}
        >
          <div style={{ padding: "20px" }}>
            <p style={bioStyle}>{description}</p>
            <div style={learnMoreStyle}>
              Learn more <span style={arrowStyle}>→</span>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

const learnMoreStyle = {
  marginTop: "15px",
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'SF Pro Text', 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
  fontSize: "16px",
  color: "#007BFF", // Standard blue for links
  cursor: "pointer",
  textAlign: "right",
};

const arrowStyle = {
  marginLeft: "5px",
  transition: "margin-left 0.3s ease",
};

export default CardItem;
