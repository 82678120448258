import React from "react";
import graduation_cap from "./images/icons/graduation_cap.png";
import { Row, Col, Card, Image } from "react-bootstrap";
import graduation_img from "./images/my_imgs/graduation_img.png";
import { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import {
  sectionHeadingStyle,
  sectionContainerStyle,
  headingContainerStyle,
  bioStyle,
} from "./components/styles";
const educationData = [
  {
    unit: "FIT4002 - Software Engineering Industry Experience",
    numberGrade: 92,
    textGrade: "HD",
  },
  {
    unit: "FIT3178 - iOS App Development",
    numberGrade: 98,
    textGrade: "HD",
  },
  {
    unit: "FIT1051 - Programming Fundamentals in Java",
    numberGrade: 92,
    textGrade: "HD",
  },
  {
    unit: "FIT3146 - Maker Lab",
    numberGrade: 89,
    textGrade: "HD",
  },
  {
    unit: "FIT3171 - Databases",
    numberGrade: 84,
    textGrade: "HD",
  },
  {
    unit: "FIT3170 - Software Engineering Practice",
    numberGrade: 93,
    textGrade: "HD",
  },
  {
    unit: "FIT2081 - Mobile Application Development",
    numberGrade: 85,
    textGrade: "HD",
  },
  {
    unit: "FIT4165 - Computer Networks",
    numberGrade: 87,
    textGrade: "HD",
  },
  {
    unit: "FIT4003 - Software Engineering Research Project",
    numberGrade: 83,
    textGrade: "HD",
  },
  {
    unit: "FIT5145 - Introduction to Data Science",
    numberGrade: 85,
    textGrade: "HD",
  },
  {
    unit: "FIT2004 - Algorithms and Data Structures",
    numberGrade: 75,
    textGrade: "D",
  },
  {
    unit: "FIT3077 - Software Engineering: Architecture and Design",
    numberGrade: 81,
    textGrade: "HD",
  },
  {
    unit: "FIT3159 - Computer Architecture",
    numberGrade: 75,
    textGrade: "D",
  },
  {
    unit: "MAT1830 - Discrete Mathematics for Computer Science",
    numberGrade: 87,
    textGrade: "HD",
  },
  {
    unit: "FIT2100 - Operating Systems",
    numberGrade: 74,
    textGrade: "D",
  },
  {
    unit: "FIT3143 - Parallel Computing",
    numberGrade: 80,
    textGrade: "HD",
  },
  {
    unit: "FIT3179 - Data Visualisation",
    numberGrade: 83,
    textGrade: "HD",
  },
];

const Education = () => {
  return (
    <div style={sectionContainerStyle}>
      <div style={headingContainerStyle}>
        <h2 style={sectionHeadingStyle}>Education</h2>
        <Image
          src={graduation_cap}
          style={{
            width: "20px",
            marginTop: "0px",
            marginLeft: "5px",
            float: "right",
          }}
        />
      </div>
      <p style={bioStyle}>
        I graduated from Monash University in 2022 with a Software Engineering
        (Honours) degree. During my time there, I focused on web and app
        development, gaining hands-on experience through various projects that
        sharpened my skills in creating dynamic web applications and innovative
        mobile apps. But I'm not that nerd ^^
      </p>

      <div style={scrollableContainerStyle}>
        <div style={scrollableContentStyle}>
          {educationData.map((item, index) => (
            <div key={index} style={containerStyle}>
              <div style={unitCodeStyle}>
                <span>{item.unit.split(" ")[0]}</span>
              </div>
              <div style={unitNameStyle}>
                <span>{item.unit.split("-").slice(1).join("-")}</span>
              </div>
              <div style={marksStyle}>
                <span>{item.numberGrade}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const scrollableContainerStyle = {
  maxHeight: "60vh", // Limit the height of the container
  overflowY: "auto", // Enable vertical scrolling
  paddingRight: "15px", // Add padding for content visibility
  marginRight: "-15px", // Shift content to hide the scrollbar
  "-msOverflowStyle": "none", // Hide scrollbar in Internet Explorer and Edge
  scrollbarWidth: "none", // Hide scrollbar in Firefox
};

const containerStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "10px",
  padding: "8px 12px", // Reduced padding for a more compact look
  background: "#fff",
  borderRadius: "6px", // Slightly rounded corners
  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)", // Subtle shadow for the cards
};

const scrollableContentStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "-5px", // Space between cards
};

const unitCodeStyle = {
  flex: "0 0 25%", // Adjust this width as needed for the unit code
  textAlign: "left",
};

const unitNameStyle = {
  flex: "1",
  textAlign: "left",
};

const marksStyle = {
  flex: "0 0 15%", // Adjust this width as needed for the marks
  textAlign: "right",
};

export default Education;
