import React, { useState, useEffect } from "react";
import { Carousel, Row, Col, Image } from "react-bootstrap";
import picky from "./images/projects/picky.png";
import voicify from "./images/projects/voicify.png";
import testing from "./images/projects/testing.png";
import covid from "./images/projects/covid.png";
import CardItem from "./components/Card";
import work_icon from "./images/icons/work_icon.png";
import {
  sectionHeadingStyle,
  headingContainerStyle,
  bioStyle,
} from "./components/styles";

const ProjectSection = () => {
  const [index, setIndex] = useState(0);
  const [loopedProjects, setLoopedProjects] = useState([]);

  const projectsData = [
    {
      title: "Picky",
      subtitle: "Mobile Social Apps",
      description:
        "Save your time on decision making and have some fun! Whether it be choosing photos to upload on social media or your OOTD, Picky is here to help. Download now to be a part of our fun-loving community.",
      image: picky,
      link: "https://picky.social/",
    },
    {
      title: "Voicify",
      subtitle: "Research Project",
      description:
        "Using a novel deep learning command parser, Voicify interprets human verbal input and performs matching with UI elements. The tool can directly open a specific feature from installed applications by fetching application code information to explore the set of in-app components.",
      image: voicify,
      link: "https://dl.acm.org/doi/abs/10.1145/3581998",
    },
    {
      title: "Accessibility Testing Tool",
      subtitle: "Research Project",
      description:
        "An automated Android testing tool integrated with multiple modules to report usability and accessibility issues. The main objective is to automatically detect issues of given APKs on multiple devices with various device settings.",
      image: testing,
      link: "https://github.com/vuminhduc796/AccessibilityTool",
    },
    {
      title: "COVID-19 Visualisation Dashboard",
      subtitle: "Web dev & Visualisations",
      description:
        "A self-hosted web page that displays COVID-19 data for different countries in the world using D3 and Vegalite. It was submitted as an assignment at Monash University.",
      image: covid,
      link: "https://github.com/vuminhduc796/FIT3179-Assignment2",
    },
  ];

  const chunkSize = (size) => {
    if (size >= 1200) return 3; // Display 3 items for large screens
    if (size >= 768) return 2; // Display 2 items for medium screens
    return 1; // Display 1 item for small screens
  };

  useEffect(() => {
    const size = chunkSize(window.innerWidth);
    const duplicatedItems = projectsData.slice(0, size);
    setLoopedProjects([...projectsData, ...duplicatedItems]);
  }, []);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    if (index === loopedProjects.length - chunkSize(window.innerWidth)) {
      setTimeout(() => setIndex(0), 500); // Simulate loop with reset
    }
  }, [index, loopedProjects]);

  const groupedProjects = [];
  for (
    let i = 0;
    i < loopedProjects.length;
    i += chunkSize(window.innerWidth)
  ) {
    groupedProjects.push(
      loopedProjects.slice(i, i + chunkSize(window.innerWidth))
    );
  }

  return (
    <div style={sectionContainerStyle}>
      <div style={headingContainerStyle}>
        <h2 style={sectionHeadingStyle}>Projects</h2>
        <Image
          src={work_icon}
          style={{
            width: "20px",
            marginLeft: "10px",
          }}
        />
      </div>

      <p style={bioStyle}>
        These are a few publicly available projects I've worked on. I firmly
        believe that the most effective way to learn coding is by diving into
        actual projects. With each project, I immerse myself in the process,
        learning and growing along the way. The hands-on experience has been
        invaluable in shaping my skills and deepening my understanding of the
        coding craft.
      </p>

      <Carousel
        activeIndex={index}
        onSelect={handleSelect}
        interval={3000}
        controls={true}
        indicators={true}
        fade={false}
        style={carouselStyle}
      >
        {groupedProjects.map((group, i) => (
          <Carousel.Item key={i}>
            <Row>
              {group.map((object, index) => (
                <Col key={index} xs={12} sm={12} md={6} lg={4}>
                  <CardItem obj={object} />
                </Col>
              ))}
            </Row>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

const sectionContainerStyle = {
  minHeight: "100vh", // Ensure the section takes up at least full screen height
  padding: "5vw", // Padding to ensure spacing from screen edges
  paddingTop: "1vw",
  backgroundColor: "#F7F8FA", // Consistent background color
  textAlign: "center", // Center the text for a clean look
};

const carouselStyle = {
  maxWidth: "100%", // Make carousel take up full width of container
  margin: "0 auto", // Center the carousel
};

export default ProjectSection;
