import React from "react";

import {
  sectionHeadingStyle,
  sectionContainerStyle,
  headingContainerStyle,
  bioStyle,
} from "./components/styles";
import { Image } from "react-bootstrap";
import SkillCardItem from "./components/SkillCard";

const Personality = () => {
  const personalityTraits = [
    { title: "Funny" },
    { title: "Innovative" },
    { title: "Communicative" },
    { title: "Self-Motivated" },
    { title: "Goal-Oriented" },
    { title: "Open-Minded" },
    { title: "Reliable" },
    { title: "Curious" },
    { title: "Creative" },
    { title: "Resilient" },
    { title: "Adaptable" },
    { title: "Collaborative" },
    { title: "Detail-Oriented" },
    { title: "Analytical" },
    { title: "Proactive" },
    { title: "Problem-Solver" },
  ];

  const halfPersonalityTraits =
    window.innerWidth <= 768
      ? personalityTraits.slice(0, Math.ceil(personalityTraits.length / 1))
      : personalityTraits;

  return (
    <div style={sectionContainerStyle}>
      <div style={headingContainerStyle}>
        <h2 style={sectionHeadingStyle}>Personality</h2>{" "}
      </div>
      <p style={bioStyle}>
        This is how I am at work and in-person, ranking by how soon you see it.
      </p>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          overflowX: "auto",
          gap: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            overflowX: "auto",
            paddingBottom: "20px",
            gap: "10px",
          }}
        >
          {halfPersonalityTraits.map((object, i) => (
            <div
              key={i}
              style={{ flex: "0 0 auto", width: "auto", margin: "0px" }}
            >
              <SkillCardItem obj={object} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Personality;
